import type { AppData } from '../../viewer.app';
import type { PostCategoriesApi } from '../Post Categories/types';
import type { PostCountersApi } from '../Post Counters New/types';
import type { PostMetadataApi } from '../Post Metadata/viewer.controller';
import type { PostTagsApi } from '../Post Tags/viewer.controller';
import type { PostTitleApi } from '../Post Title/viewer.controller';
import blocksModel from './model';

const selectors = {
  root: '#headerRoot',
  counters: '#postCountersNew1',
  categories: '#categories1',
  tags: '#tags1',
  metadata: '#metadata1',
  ratings: '#ratings1',
  heroImage: '#image',
  title: '#title1',
} as const;

export default blocksModel.createController(
  ({ $w, appData: _appData, $widget, flowAPI }) => {
    const appData = _appData as AppData;

    let components: ReturnType<typeof getComponents>;
    const getComponents = () => ({
      root: $w(selectors.root) as $w.Box,
      counters: $w(selectors.counters) as PostCategoriesApi,
      categories: $w(selectors.categories),
      tags: $w(selectors.tags) as PostTagsApi,
      metadata: $w(selectors.metadata) as PostMetadataApi,
      ratings: $w(selectors.ratings),
      heroImage: $w(selectors.heroImage) as $w.Image,
      title: $w(selectors.title) as PostTitleApi,
    });

    return {
      pageReady: async () => {
        components = getComponents();
        await appData.subjects.postPageRenderModel.subscribe((model) => {
          if (model?.heroImageUrl) {
            components.heroImage.src = model.heroImageUrl;
          }

          components.categories.initialize(model);
          components.title.initialize(model);
          components.ratings.initialize(model);
          components.metadata.initialize(model);
          if ('initialize' in components.counters) {
            components.counters.initialize(model);
          }
          components.tags.initialize(model);
        });

        const isCommentsEnabled = $widget.props.isCommentsPluginInstalled;
        const isRatingsEnabled = $widget.props.isRatingsEnabled;

        const shouldShowRatingsElement = isCommentsEnabled && isRatingsEnabled;

        if (shouldShowRatingsElement) {
          components.ratings.restore();
        } else {
          components.ratings.delete();
        }

        if (flowAPI.environment.isEditor) {
          $widget.onPropsChanged((_, newProps) => {
            if (newProps.isRatingsEnabled) {
              components.ratings.restore();
            } else {
              components.ratings.delete();
            }
          });
        }
      },
      exports: {
        counters: () => $w(selectors.counters) as PostCountersApi,
      },
    };
  },
);
